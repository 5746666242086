import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import fitvids from 'fitvids';
import 'sidr/dist/jquery.sidr';
import Packery from 'packery';
import 'slick-carousel';

// Stop browser from automatically scrolling to anchor
if (window.location.hash) scroll(0, 0);
setTimeout(function () {
	scroll(0, 0);
}, 1);

$(function () {
	// Scroll to anchor
	if (window.location.hash && $(window.location.hash).length) {
		$('html, body').animate(
			{
				scrollTop: $(window.location.hash).offset().top - 50 + 'px',
			},
			600,
			'swing'
		);
	}
});

$(function () {
	// $(window).scroll(function(event) {
	// 	if ($(window).scrollTop() <= 0) {
	// 		$('body').removeClass('scrolled');
	// 	} else {
	// 		$('body').addClass('scrolled');
	// 	}
	// });

	/*----------  Slick.js sliders  ----------*/

	// Slick
	$('.slick-wrapper').each(function () {
		var slider = $(this)
			.find('.slick-slides')
			.slick({
				arrows: true,
				dots: true,
				infinite: true,
				slide: '.slide',
				speed: '500',
				appendArrows: $(this).find('.slick-controls'),
				prevArrow:
					'<button type="button" data-role="none" class="slick-arrow slick-arrow-left plain-button" aria-label="Previous" tabindex="0" role="button"><</button>',
				nextArrow:
					'<button type="button" data-role="none" class="slick-arrow slick-arrow-right plain-button" aria-label="Next" tabindex="0" role="button">></button>',
				appendDots: $(this).find('.slick-dots-wrapper'),
			});

		// Set half rotator to autoplay
		if ($(this).parents('.half-rotator').length) {
			slider.slick('slickSetOption', 'autoplay', true, true);
			slider.slick('slickSetOption', 'autoplaySpeed', 7000, true);
		}
	});

	// Stop autoplay when slide control is clicked
	$('.slick-arrow').on('click', function (event) {
		$(this)
			.parents('.slick-wrapper')
			.find('.slick-slides')
			.slick('slickSetOption', 'autoplay', false, true);
	});

	// Reposition arrows after resizing Slick
	$('.slick-wrapper').on('setPosition', function (slick) {
		half_rotator_arrows();
	});

	/*----------  Nav and search  ----------*/

	// Mobile nav
	if ($('#sidr').length) {
		$('.nav-trigger-mobile').sidr({
			side: 'left',
			onOpen: function () {
				$('#sidr .nav-focus-item').attr('tabindex', '0');
			},
			onClose: function () {
				$('#sidr .nav-focus-item').attr('tabindex', '-1');
			},
		});
	}

	// Clone nav
	$('#sidr').children().clone().appendTo('.secondary-col .side-nav');

	// Set the appropriate toggle and nav state
	set_nav_variant($(window).width());

	// Desktop nav toggle
	$('.nav-trigger-desktop').click(function (event) {
		var windowWidth = $(window).width();
		if ($('body').hasClass('nav-open')) {
			localStorage.setItem('navOpen', 0);
			hide_desktop_nav(windowWidth);
			$(this).removeClass('open');
			$('.secondary-col .side-nav .nav-focus-item').attr(
				'tabindex',
				'-1'
			);
		} else {
			localStorage.setItem('navOpen', 1);
			position_desktop_nav(windowWidth, true);
			$(this).addClass('open');
			$('.secondary-col .side-nav .nav-focus-item').attr('tabindex', '0');
		}
		event.preventDefault();
	});

	// Close desktop nav when a link is clicked
	$('.secondary-col .side-nav a').click(function (event) {
		localStorage.setItem('navOpen', 0);
		var windowWidth = $(window).width();
		hide_desktop_nav(windowWidth, true);
		$('.nav-trigger-desktop').removeClass('open');
		$('.secondary-col .side-nav .nav-focus-item').attr('tabindex', '-1');
	});

	// Toggle sub nav
	$('.sub-nav-trigger').on('click', function () {
		$('.sub-nav-open').toggle();
		$('.sub-nav-content').toggle();
		event.preventDefault();
	});

	// Close sub nav on any click not on the sub nav
	$(document).mouseup(function (e) {
		var container = $('.sub-nav-content');
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$('.sub-nav-open').show();
			$('.sub-nav-content').hide();
		}
	});

	/*----------  Media lightboxes  ----------*/

	Fancybox.bind('.fancybox-youtube', {
		openEffect: 'none',
		closeEffect: 'none',
		helpers: {
			media: {},
		},
	});

	Fancybox.bind('.fancybox-vimeo', {
		openEffect: 'none',
		closeEffect: 'none',
		helpers: {
			media: {},
		},
	});

	Fancybox.bind('.fancybox-kaltura', {
		openEffect: 'none',
		closeEffect: 'none',
		type: 'ajax',
		width: 700,
	});

	/*----------  Misc processing  ----------*/

	// Preload hover icons
	preload([
		'/images/icon-close-black.svg',
		'/images/icon-close-red.svg',
		'/images/icon-close-white.svg',
		'/images/icon-down-arrow-white.svg',
	]);

	// Style offsite button links
	$('.button-with-arrow').each(function () {
		if (link_is_external(this)) {
			$(this).attr('target', '_blank');
			$(this).find('.icon-arrow-right').addClass('icon-arrow-up-right');
			$(this).find('.icon-arrow-right').removeClass('icon-arrow-right');
		}
	});

	// Style offsite sidr and sub nav links
	$('.blank-node').each(function () {
		if (link_is_external(this)) {
			$(this).attr('target', '_blank');
			$(this).addClass('blank-node-arrow');
		}
	});

	// Size video embeds
	fitvids('.video-embed-block');

	/*----------  List modules and packery  ----------*/

	// Open/close .full-short-list
	$('.full-short-list__toggle').click(function () {
		$(this).parent().toggleClass('open');
		$(this)
			.parent()
			.find('.list-content')
			.slideToggle(300, function () {
				toggle_aria_expanded($(this).parent().find('.list-content'));
			});
	});

	// Set .packery-cell height BEFORE initializing Packery
	square_list_cell(
		$('.packery-cell:not(.packery-is-expanded) .packery-cell-trigger')
	);

	$('.packery-grid').each(function (i, container) {
		var packeryGrid = new Packery(container, {
			itemSelector: '.packery-cell',
			gutter: '.packery-gutter-sizer',
			percentPosition: true,
		});

		// Open/close .full-long-list
		$('.full-long-list__toggle').on('click', function (event) {
			event.stopImmediatePropagation();
			$(this).closest('.full-long-list').toggleClass('long-list-open');
			$(this).parent().siblings('.long-list-content').toggle();
			toggle_aria_expanded(
				$(this).parent().siblings('.long-list-content')
			);
			if ($(this).siblings('.long-list-view-button').is(':hidden')) {
				$(this)
					.siblings('.long-list-view-button')
					.css('display', 'inline-block');
			} else {
				$(this).siblings('.long-list-view-button').hide();
			}
			square_list_cell(
				$(this)
					.closest('.full-long-list')
					.find(
						'.packery-cell:not(.packery-is-expanded) .packery-cell-trigger'
					)
			);

			// Short delay to allow for content to expand before Packery layout
			setTimeout(function () {
				packeryGrid.layout();
			}, 100);
		});

		// Toggle .full-long-list view style
		$('.long-list-view-button').on('click', function (event) {
			event.stopImmediatePropagation();
			console.log('click');
			if ($(this).hasClass('viewing-list')) {
				$(this).find('span').html($(this).attr('default-text'));
			} else {
				$(this).find('span').html($(this).attr('secondary-text'));
			}
			$(this).toggleClass('viewing-list');

			$(this).closest('.full-long-list').find('.packery-hider').toggle();
			toggle_aria_expanded(
				$(this).closest('.full-long-list').find('.packery-hider')
			);

			$(this)
				.closest('.full-long-list')
				.closest('.full-long-list')
				.find('.long-list-text')
				.toggle();
			toggle_aria_expanded(
				$(this)
					.closest('.full-long-list')
					.closest('.full-long-list')
					.find('.long-list-text')
			);

			square_list_cell(
				$(this)
					.closest('.full-long-list')
					.find(
						'.packery-cell:not(.packery-is-expanded) .packery-cell-trigger'
					)
			);
			packeryGrid.layout();
		});

		// Open/close Packery grid items
		var elementsArray = container.querySelectorAll('.packery-cell-trigger');
		elementsArray.forEach(function (elem) {
			elem.addEventListener('click', function () {
				// Change size of items by toggling .packery-is-expanded class
				if (
					$(event.currentTarget)
						.parent()
						.hasClass('packery-is-expanded')
				) {
					$(event.currentTarget)
						.parent()
						.parent()
						.find('.packery-cell')
						.removeClass('packery-is-expanded');
				} else {
					$(event.currentTarget)
						.parent()
						.parent()
						.find('.packery-cell')
						.removeClass('packery-is-expanded');
					$(event.currentTarget)
						.parent()
						.toggleClass('packery-is-expanded');
				}
				// Trigger re-layout after item size changes
				packeryGrid.layout();
			});
		});

		packeryGrid.on('layoutComplete', function (event, laidOutItems) {
			square_list_cell($(laidOutItems).find('.packery-cell-trigger'));
		});
	});

	$('.long-list-content').hide();

	/*----------  Tall Vertical Blinds  ----------*/

	$('.tall-vertical-blinds .blind').hover(
		function () {
			// Mouse enter
			open_tall_vertical_blinds($(this));
		},
		function () {
			// Mouse leave
			close_tall_vertical_blinds($(this));
		}
	);

	$('.tall-vertical-blinds .blind').focusin(function () {
		open_tall_vertical_blinds($(this));
	});

	$('.tall-vertical-blinds .blind').focusout(function () {
		close_tall_vertical_blinds($(this));
	});

	function open_tall_vertical_blinds(target) {
		var expandedWidth = $(target).parent().data('expanded-width');
		var collapsedWidth = $(target).parent().data('collapsed-width');
		var siblings = $(target).siblings('.blind');
		var defaultText = $(target).find('.tall-blind-content');
		var siblingsDefaultText = siblings.find('.tall-blind-content');
		TweenMax.to(target, 0.4, {
			width: expandedWidth,
			onComplete: function () {
				var activeText = $(this.target).find(
					'.tall-blind-expanded-content'
				);
				TweenMax.to(activeText, 0.4, {
					opacity: 1,
					bottom: 20,
				});
			},
		});
		TweenMax.to(siblings, 0.4, {
			width: collapsedWidth,
		});
		TweenMax.to(defaultText, 0.2, {
			opacity: 0,
			bottom: -20,
		});
		TweenMax.to(siblingsDefaultText, 0.2, {
			opacity: 1,
			bottom: 0,
		});
	}

	function close_tall_vertical_blinds(target) {
		var defaultWidth = $(target).parent().data('default-width');
		var siblings = $(target).siblings('.blind');
		var activeText = $(target).find('.tall-blind-expanded-content');
		TweenMax.to(target, 0.4, {
			width: defaultWidth,
			onComplete: function () {
				var defaultText = $(this.target).find('.tall-blind-content');
				TweenMax.to(defaultText, 0.2, {
					opacity: 1,
					bottom: 0,
				});
			},
		});
		TweenMax.to(siblings, 0.4, {
			width: defaultWidth,
		});
		TweenMax.to(activeText, 0.1, {
			opacity: 0,
			bottom: 0,
		});
	}

	/*----------  Short Vertical Blinds  ----------*/

	$('.short-vertical-blinds .blind').hover(function () {
		open_short_vertical_blinds($(this));
	});

	$('.short-vertical-blinds .blind').focusin(function () {
		open_short_vertical_blinds($(this));
	});

	function open_short_vertical_blinds(target) {
		var siblings = $(target).siblings('.blind');
		var siblingsText = siblings.find('.short-blind-expanded-content');
		TweenMax.to(target, 0.3, {
			width: '50%',
			onComplete: function () {
				var activeText = $(this.target).find(
					'.short-blind-expanded-content'
				);
				TweenMax.to(activeText, 0.3, {
					opacity: 1,
					top: 0,
				});
			},
		});
		TweenMax.to(siblings, 0.3, {
			width: '16.666%',
		});
		TweenMax.to(siblingsText, 0.1, {
			opacity: 0,
			top: 10,
		});
	}

	/*----------  Banner caption flyout  ----------*/
	var $banner_caption = $('.banner-caption').first();
	var $banner_toggle = $banner_caption.find('.banner-caption__toggle');
	var banner_caption_width = $banner_caption.width() + 10;
	$banner_caption.css('margin-right', -banner_caption_width);

	$('.banner-caption__toggle').click(function (event) {
		if ($banner_caption.hasClass('open')) {
			var banner_caption_width = $banner_caption.width() + 10;
			$banner_caption.animate(
				{ 'margin-right': -banner_caption_width },
				300
			);
			$banner_caption.removeClass('open');
		} else {
			$banner_caption.animate({ 'margin-right': 0 }, 300);
			$banner_caption.addClass('open');
		}
		$banner_toggle.toggleClass('icon-info');
		$banner_toggle.toggleClass('icon-cancel-circle');
	});

	/*----------  Window.load/resize processing  ----------*/

	$(window).load(function () {
		equal_heights($('.full-spotlight-cell'));
		equal_heights($('.full-profile-cell'));
		equal_heights($('.three-up-with-dividers .cell'));
		equal_heights($('.short-vertical-blinds .blind'));
		background_sizer($('.full-teaser-background'), $('.teaser-text'));
		set_short_blinds($('.short-vertical-blinds img'));
		half_rotator_arrows();
		position_banner_heading();
		position_sub_nav();

		$('.slick-slides').slick('setPosition');

		var windowWidth = $(window).width();
		if (windowWidth <= 900) {
			background_sizer(
				$('.tall-vertical-blinds .blind'),
				$('.tall-blind-expanded-content')
			);
		}
	});

	$(window).resize(function () {
		$.sidr('close');
		equal_heights($('.full-spotlight-cell'));
		equal_heights($('.full-profile-cell'));
		equal_heights($('.short-vertical-blinds .blind'));
		equal_heights($('.three-up-with-dividers .cell'));
		background_sizer($('.full-teaser-background'), $('.teaser-text'));
		square_list_cell(
			$('.packery-cell:not(.packery-is-expanded) .packery-cell-trigger')
		);
		set_short_blinds($('.short-vertical-blinds img'));
		position_sub_nav();
		position_banner_heading();

		var banner_caption_width = $banner_caption.width() + 10;
		$banner_caption.css('margin-right', -banner_caption_width);
		$banner_toggle.removeClass('icon-cancel-circle');
		$banner_toggle.addClass('icon-info');
		$banner_caption.removeClass('open');

		var windowWidth = $(window).width();
		if (windowWidth <= 900) {
			background_sizer(
				$('.tall-vertical-blinds .blind'),
				$('.tall-blind-expanded-content')
			);
		}
		$('.slick-slides').slick('setPosition');

		set_nav_variant(windowWidth);

		$('.full-long-list').each(function (index) {
			square_list_cell(
				$(this).find(
					'.packery-cell:not(.packery-is-expanded) .packery-cell-trigger'
				)
			);
		});
	});
});

/*=========================================
=            Utility functions            =
=========================================*/

// Trigger click on space or enter keyup on role="button" elements
$('[role="button"]').keyup(function (event) {
	if (event.which === 32 || event.which === 13) {
		$(this).trigger('click');
		event.preventDefault();
	}
});

// Stop browser window from scrolling when pressing space key while focused on a [role="button"] element
$('[role="button"]').keypress(function (event) {
	if (event.which === 32) {
		event.preventDefault();
	}
});

function set_nav_variant(windowWidth) {
	// Show appropriate nav trigger and nav
	if (windowWidth >= 1160) {
		position_desktop_nav(windowWidth);
		$('.nav-trigger-desktop').show();
		$('.nav-trigger-mobile').hide();
		$('.primary-col').css('float', 'right');
	} else {
		hide_desktop_nav();
		$('.nav-trigger-desktop').hide();
		$('.nav-trigger-mobile').show();
		$('.primary-col').css('float', 'none');
	}
}

function position_desktop_nav(windowWidth, animate) {
	var windowWidth = $(window).width();
	var primaryWidth = windowWidth - 260;

	if (localStorage.getItem('navOpen') == 1) {
		if (animate) {
			$('.primary-col').animate(
				{ width: primaryWidth },
				300,
				function () {
					$('body').addClass('nav-open');
					$('.slick-slides').slick('setPosition');
					set_short_blinds($('.short-vertical-blinds img'));
					equal_heights($('.short-vertical-blinds .blind'));

					equal_heights($('.full-spotlight-cell'));
					equal_heights($('.three-up-with-dividers .cell'));
					background_sizer(
						$('.full-teaser-background'),
						$('.teaser-text')
					);
					half_rotator_arrows();
					$('.secondary-col .side-nav .nav-focus-item').attr(
						'tabindex',
						'0'
					);
				}
			);
		} else {
			$('.primary-col').css('width', primaryWidth);
			$('body').addClass('nav-open');
			$('.slick-slides').slick('setPosition');
			set_short_blinds($('.short-vertical-blinds img'));
			equal_heights($('.short-vertical-blinds .blind'));

			equal_heights($('.full-spotlight-cell'));
			equal_heights($('.three-up-with-dividers .cell'));
			background_sizer($('.full-teaser-background'), $('.teaser-text'));
			half_rotator_arrows();
			$('.secondary-col .side-nav .nav-focus-item').attr('tabindex', '0');
		}
	}
}

function hide_desktop_nav(windowWidth) {
	$('.primary-col').animate({ width: windowWidth }, 300, function () {
		$('body').removeClass('nav-open');
		$('.primary-col').css('width', '100%');
		$('.slick-slides').slick('setPosition');
		set_short_blinds($('.short-vertical-blinds img'));
		equal_heights($('.short-vertical-blinds .blind'));

		equal_heights($('.full-spotlight-cell'));
		equal_heights($('.three-up-with-dividers .cell'));
		background_sizer($('.full-teaser-background'), $('.teaser-text'));
		half_rotator_arrows();
		$('.secondary-col .side-nav .nav-focus-item').attr('tabindex', '-1');
	});
}

// If .banner-heading is one line set a small overlap with banner
function position_banner_heading() {
	var windowWidth = $(window).width();
	var bannerHeight = $('.full-banner-block .banner-heading').outerHeight();
	if (bannerHeight < 140 && windowWidth > 650) {
		if ($('.banner-heading').hasClass('banner-heading--with-category')) {
			var categoryHeading =
				$('.banner-category-heading').outerHeight() + 57;
			$('.banner-heading').css({
				'margin-top': '-' + categoryHeading + 'px',
			});
		} else {
			$('.banner-heading').css({ 'margin-top': '-48px' });
		}

		$('.full-tall-banner-block').addClass('small-gradient');
	} else {
		$('.banner-heading').removeAttr('style');
		$('.full-tall-banner-block').removeClass('small-gradient');
	}
}

// Position sub nav
function position_sub_nav() {
	var windowWidth = $(window).width();
	if (windowWidth > 1600) {
		var subNavHorizontal = (windowWidth - 1600) / 2;
	} else {
		var subNavHorizontal = -1;
	}

	// If short banner
	if ($('.banner-image').hasClass('short-banner-image')) {
		if (windowWidth <= 800) {
			var bannerHeight = $('.banner-image').outerHeight() - 5;
		} else {
			var bannerHeight = $('.full-banner-block').outerHeight() + 15;
			// console.log(bannerHeight);
		}
	}
	// If tall banner
	else {
		if (windowWidth <= 800 && windowWidth > 650) {
			var bannerHeight = $('.banner-image').outerHeight() - 170;
		} else if (windowWidth <= 650) {
			var bannerHeight = $('.banner-image').outerHeight() - 46;
		} else {
			var bannerHeight = $('.full-banner-block').outerHeight() + 15;
		}
	}

	$('.sub-nav').css({
		top: bannerHeight,
		left: subNavHorizontal,
	});
}

// Position half rotator arrows at the bottom of the images
function half_rotator_arrows() {
	var image_height = $('.half-rotator').find('img').height();
	$('.half-rotator .slick-arrow').css('top', image_height + 'px');
}

// Fix rounding errors in all versions of IE. Existing CSS works in all other browsers without JQuery.
function equal_heights_padding(target) {
	target.css('height', 'auto');
	var heights = new Array();
	target.each(function (event) {
		heights.push($(this).outerHeight());
	});
	var tallest = Math.max.apply(Math, heights);
	target.outerHeight(tallest);
	target.css({ 'padding-bottom': '0' });
}

// Equal heights
function equal_heights(target) {
	target.css('height', 'auto');
	var heights = new Array();
	target.each(function (event) {
		heights.push($(this).outerHeight());
	});
	var tallest = Math.max.apply(Math, heights);
	target.outerHeight(tallest);
}

// Set heights of short vertical blind images
function set_short_blinds(target) {
	target.css('width', '100%');
	var widths = new Array();
	target.each(function (event) {
		widths.push($(this).outerWidth());
	});
	var narrowest = Math.min.apply(Math, widths);
	target.outerWidth(narrowest);
}

// Keep .packery-cell square
function square_list_cell(target) {
	var width = $(target).outerWidth();
	$(target).css('min-height', width + 'px');
}

// Expand target if absolutely positioned content is taller then the target container
function background_sizer(target, content, parent) {
	$(target).each(function (i, loop_target) {
		var target_height = $(loop_target).height();
		var content_height = $(loop_target).find(content).outerHeight();
		// Reset height
		$(loop_target).css('height', 'auto');

		if (content_height > target_height) {
			$(loop_target).css('height', content_height + 'px');
		} else {
			$(loop_target).css('height', 'auto');
		}

		// If parent option is true expand the target to its parent's height if necessary
		// Used when equal_heights sets the parent height
		if (parent == true) {
			var parent_height = $(loop_target).parent().height();

			if (parent_height > content_height) {
				$(loop_target).css('height', '100%');
			}
		}
	});
}

// Preload hover images
function preload(arrayOfImages) {
	$(arrayOfImages).each(function () {
		$('<img/>')[0].src = this;
	});
}

// Add .external class to offsite links
function link_is_external(link_element) {
	return link_element.host !== window.location.host;
}

// Toggle ARIA expanded
function toggle_aria_expanded(toggle_element) {
	if ($(toggle_element).attr('aria-expanded') == 'true') {
		$(toggle_element).attr('aria-expanded', 'false');
	} else {
		$(toggle_element).attr('aria-expanded', 'true');
	}
}
